var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nowProductObj
    ? _c(
        "div",
        { staticClass: "unlockSingle" },
        [
          _c("div", { staticClass: "unlockSingle__bg" }),
          _c("div", { staticClass: "unlockSingle__title" }, [
            _c("div", { staticClass: "unlockSingle__title__inside" }, [
              _c("h1", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.nowProductObj.Code == "All"
                        ? "全部購買"
                        : _vm.nowProductObj.cn + "座"
                    ) +
                    "\n      "
                )
              ]),
              _c("h2", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.nowProductObj.Code == "All"
                        ? _vm.nowProductObj.Name.split("-")[0]
                        : _vm.nowProductObj.en
                    ) +
                    "\n      "
                )
              ])
            ])
          ]),
          _c("div", {
            staticClass: "unlockSingle__quote",
            domProps: { innerHTML: _vm._s(_vm.nowProductObj.Description) }
          }),
          _c("div", { staticClass: "unlockSingle__intro" }, [
            _vm._m(0),
            _c("div", { staticClass: "unlockSingle__intro__content" }, [
              _c("div", {
                staticClass: "unlockSingle__intro__content__word",
                domProps: { innerHTML: _vm._s(_vm.nowProductObj.Introduction) }
              })
            ])
          ]),
          _c("div", { staticClass: "unlockSingle__toBuy" }, [
            _c("div", { staticClass: "unlockSingle__toBuy__useType" }, [
              _c(
                "div",
                { staticClass: "unlockSingle__toBuy__useType__radio" },
                [
                  _c("label", { attrs: { for: "personal" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.useType,
                          expression: "useType"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name: "useType",
                        id: "personal",
                        value: "personal"
                      },
                      domProps: { checked: _vm._q(_vm.useType, "personal") },
                      on: {
                        change: [
                          function($event) {
                            _vm.useType = "personal"
                          },
                          function($event) {
                            _vm.receiverMemberId = ""
                          }
                        ]
                      }
                    }),
                    _c("span", [_vm._v("自用")])
                  ]),
                  _c("label", { attrs: { for: "gift" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.useType,
                          expression: "useType"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name: "useType",
                        id: "gift",
                        value: "gift"
                      },
                      domProps: { checked: _vm._q(_vm.useType, "gift") },
                      on: {
                        change: function($event) {
                          _vm.useType = "gift"
                        }
                      }
                    }),
                    _c("span", [_vm._v("送禮")])
                  ])
                ]
              ),
              _vm.useType == "gift"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "unlockSingle__toBuy__useType__receiverMember"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "unlockSingle__toBuy__useType__receiverMember__id"
                        },
                        [
                          _c("label", { attrs: { for: "receiverMemberId" } }, [
                            _vm._v("送禮對象 ID")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.receiverMemberId,
                                expression: "receiverMemberId",
                                modifiers: { trim: true }
                              }
                            ],
                            attrs: {
                              type: "text",
                              id: "receiverMemberId",
                              placeholder: "請輸入對方帳號 ID"
                            },
                            domProps: { value: _vm.receiverMemberId },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.receiverMemberId = $event.target.value.trim()
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass: "unlockSingle__toBuy__buyArea",
                class: { isAll: _vm.nowProductObj.Code == "All" }
              },
              [
                _c(
                  "div",
                  { staticClass: "unlockSingle__toBuy__buyArea__price" },
                  [
                    _vm._v(
                      "\n        NT$ " +
                        _vm._s(_vm.nowProductObj.Amount) +
                        "\n      "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "unlockSingle__toBuy__buyArea__btn",
                    class: { disabled: _vm.alreadyBuyPersonal },
                    on: {
                      click: function($event) {
                        return _vm.toBuy(_vm.nowProductObj)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.alreadyBuyPersonal ? "已購買" : "立即購買") +
                        "\n        "
                    ),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/立即購買.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ]
            )
          ]),
          _vm.nowProductObj.Code != "All"
            ? _c("div", { staticClass: "unlockSingle__toBuyAll" }, [
                _c("div", { staticClass: "unlockSingle__toBuyAll__text" }, [
                  _vm._v("\n      購買全部星座只需 400 元喔～\n    ")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "unlockSingle__toBuyAll__btn",
                    on: {
                      click: function($event) {
                        return _vm.toBuyAll()
                      }
                    }
                  },
                  [
                    _vm._v("\n      全部購買\n      "),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/立即購買.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _c("BuyNotice", { attrs: { notice: _vm.nowProductObj.Notice } }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlreadyBuyToast,
                  expression: "showAlreadyBuyToast"
                }
              ],
              staticClass: "alreadyBuyToast"
            },
            [
              _vm._v("\n    本篇內容已解鎖\n    "),
              _c("span", { staticClass: "hidden-mobile-down" }, [_vm._v("，")]),
              _c("br", { staticClass: "hidden-mobile-up" }),
              _vm._v("\n    不需要再進行購買囉~\n  ")
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "unlockSingle__intro__title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/運勢書共用/解答之書介紹icon.svg"),
          alt: ""
        }
      }),
      _vm._v("\n      解答之書介紹\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }