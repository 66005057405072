<template>
  <div class="book">
    <!-- Book Banner -->
    <div class="book__banner">
      <div class="book__banner__textPic"></div>
      <div class="book__banner__toBuy">
        <div
          class="book__banner__toBuy__btn all"
          :class="{ isSale: isSale }"
          @click="toBuyAll()"
        >
          <div
            class="book__banner__toBuy__btn__text"
            :class="{ 'hover--hide': isSale }"
          >
            全部<br class="hidden-mobile-down" />購買
          </div>
          <div class="book__banner__toBuy__btn__text hover--show" v-if="isSale">
            <span>解鎖</span><br class="hidden-mobile-down" />2024大趨勢
          </div>
          <div class="book__banner__toBuy__btn__price">
            NT400
            <img
              src="@/assets/運勢書共用/toBuyIcon.svg"
              alt="解答之書全部購買"
              :class="{ 'hover--hide': isSale }"
            />
            <img
              src="@/assets/運勢書共用/toBuyIcon_預購白.svg"
              alt="解答之書全部購買_預購"
              class="hover--show"
              v-if="isSale"
            />
          </div>
        </div>
        <div
          class="book__banner__toBuy__btn single"
          :class="{ isSale: isSale }"
          @click="toUnlockList()"
        >
          <div
            class="book__banner__toBuy__btn__text"
            :class="{ 'hover--hide': isSale }"
          >
            單個<br class="hidden-mobile-down" />星座
          </div>
          <div class="book__banner__toBuy__btn__text hover--show" v-if="isSale">
            <span>解鎖</span><br class="hidden-mobile-down" />2024大趨勢
          </div>
          <div class="book__banner__toBuy__btn__price">
            NT100
            <img
              src="@/assets/運勢書共用/toBuyIcon.svg"
              alt="解答之書-單星座購買"
              :class="{ 'hover--hide': isSale }"
            />
            <img
              src="@/assets/運勢書共用/toBuyIcon_預購白.svg"
              alt="解答之書-單星座購買預購"
              class="hover--show"
              v-if="isSale"
            />
          </div>
        </div>
      </div>

      <!-- 手機板預購 -->
      <div class="book__banner__mobilePreorder hidden-mobile-up" v-if="isSale">
        <span
          class="wave-char"
          v-for="(char, index) in mobilePreorderText"
          :key="index"
          :style="{
            animationDelay: index * (1.0 / mobilePreorderText.length) + 's',
          }"
        >
          {{ char }}
        </span>
      </div>
    </div>
    <!-- Book Main -->
    <div class="book__main">
      <h1 class="hidden-mobile-up hidden-mobile-down">2024 解答之書</h1>
      <div class="book__main__quote">
        <div class="book__main__quote__title">
          <img
            src="@/assets/運勢書共用/2024.svg"
            alt="解答之書-裝飾圖片1"
          /><br />會是怎麼樣的一年
        </div>
        <div class="book__main__quote__content">
          2024年，冥王用「最後八個月」幫我們暖身，給出膽量讓我們嘗試。<span>動作夠快、懂抓住機會的人</span>，就有高機率能異軍崛起，甚至撼動未來二十年生涯。<br /><br />2024年四月前，也是木天合高峰，我們即將充分感受天王星能量。階級顛覆、規則打破、新生代出頭，各種創意蓬發<br /><br />面對新世界，我們不能再用舊的自己迎接<br /><span
            >需打破習性</span
          >，新的價值觀，新的表達方式面對<br /><br />你已躍躍欲試了嗎？記得喔，改變的意義不在「馬上成功」，<span>改變是為了給自己一個新的機會</span>，現在起，開始改變，你已拿到進入新世界的門票，加油喔！
        </div>
      </div>
      <div class="book__main__constellations">
        <div class="book__main__constellations__title">
          <img src="@/assets/運勢書共用/探索星座.svg" alt="解答之書-探索星座" />
        </div>
        <div class="book__main__constellations__list">
          <div
            class="book__main__constellations__list__item"
            v-for="(item, index) in book2024_list"
            :key="index"
            @click="toBuyOrRead(item)"
          >
            <div
              class="book__main__constellations__list__item__pic"
              :class="`background-${(index % 3) + 1}`"
            >
              <img :src="item.bookImg" :alt="`解答之書-${item.cn}座圖片`" />
            </div>
            <div class="book__main__constellations__list__item__title">
              <img
                src="../../assets/運勢書共用/icon-locked.svg"
                v-show="!item.isUnlock"
                alt="解答之書-需要解鎖"
              />
              {{ item.cn }}座
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Book 動畫(video) -->
    <div
      class="book__animation"
      v-if="needAnimation"
      :class="{ 'fade-out': !showAnimation }"
    >
      <div class="book__animation__video">
        <video
          ref="video"
          muted
          playsinline="true"
          webkit-playsinline="true"
          @loadedmetadata="handleVideoLoaded"
        >
          <source
            src="../../assets/運勢書共用/taiwanNo1.mp4"
            type="video/mp4"
          />
          很抱歉，您的瀏覽器似乎不支援 HTML5 的 video
          標籤。為了獲得最佳的觀看體驗，請考慮切換到一個支援 HTML5 video
          的瀏覽器。
        </video>
      </div>
      <div
        class="book__animation__loading"
        :class="{ 'fade-out': !isLoading && videoLoaded }"
      >
        <img
          class="hidden-mobile-down"
          src="../../assets/運勢書共用/Desktop-Loading.png"
          alt="loading-desktop"
          @load="initAnimation()"
        />
        <img
          class="hidden-mobile-up"
          src="../../assets/運勢書共用/Mobile-Loading.png"
          alt="loading-mobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";

export default {
  mixins: [BuyBook2024Mixin],
  metaInfo: {
    title: "唐綺陽官方專屬星盤-2024解答之書",
    meta: [
      {
        name: "title",
        content: `唐綺陽官方專屬星盤-2024解答之書`,
      },
      {
        name: "description",
        content: `面對新世界，我們不能再用舊的自己迎接。需打破習性，新的價值觀，新的表達方式面對。你已躍躍欲試了嗎？記得喔，改變的意義不在「馬上成功」，改變是為了給自己一個新的機會，現在起，開始改變，你已拿到進入新世界的門票，加油喔！`,
      },
    ],
  },
  data() {
    return {
      mobilePreorderText: [
        ..."限時優惠中".split(""),
        "　",
        ..."解鎖2024的你".split(""),
      ],
      isLoading: true,
      videoLoaded: false,
      showAnimation: true,
      needAnimation: true,
    };
  },
  mounted() {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
    // if (localStorage.getItem("alreadyWatchBook2024Animation")) {
    //   this.needAnimation = false;
    //   document.body.style.height = "auto";
    //   document.body.style.overflow = "auto";
    // }
  },
  computed: {
    book2024_list() {
      return this.$store.getters.get_book2024_list.filter((item) => {
        return item.Code !== "All";
      });
    },
    isSale() {
      return false;
    },
  },
  methods: {
    toBuyAll() {
      this.mixin_buyAll();
    },
    toUnlockList() {
      this.$router.push({
        name: "Book2024UnlockList",
      });
    },
    toBuyOrRead(item) {
      if (item.isUnlock) {
        this.$router.push({
          name: "Book2024ContentTrend",
          params: { id: item.en },
        });
      } else {
        this.$router.push({
          name: "Book2024UnlockSingle",
          params: { id: item.en },
        });
      }
    },
    initAnimation() {
      setTimeout(() => {
        this.isLoading = false;
        if (this.videoLoaded) {
          setTimeout(() => {
            this.handleVideoPlay();
            this.$refs.video.play();
          }, 950);
        }
      }, 2000);

      //如果超過六秒還沒load完，就放棄
      setTimeout(() => {
        if (!this.videoLoaded) {
          this.showAnimation = false;
        }
      }, 6000);

      //網站卡住超過10秒時，直接放棄
      setTimeout(() => {
        this.showAnimation = false;
      }, 10000);
    },
    handleVideoLoaded() {
      this.videoLoaded = true;
      if (!this.isLoading) {
        setTimeout(() => {
          this.handleVideoPlay();
          this.$refs.video.play();
        }, 900);
      }
    },
    handleVideoPlay() {
      setTimeout(() => {
        this.showAnimation = false;
        document.body.style.height = "auto";
        document.body.style.overflow = "auto";
        localStorage.setItem("alreadyWatchBook2024Animation", true);
      }, 1200);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

//撥放影片固定scroll bar
// html,
// body:has(.book__animation) {
//   height: 100vh;
//   overflow: hidden;
// }

.book {
  margin-top: -95px;
  margin-bottom: -100px;
  width: 100%;
  min-height: 248.7vw;
  background: url("../../assets/運勢書共用/運勢書介紹頁_Web.png");
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  @include mobile {
    margin-top: -60px;
    margin-bottom: -55px;
    min-height: 703.5vw;
    background: url("../../assets/運勢書共用/運勢書介紹頁_Mobile.png");
  }
  // Book Banner
  &__banner {
    width: 100%;
    height: 64.6vw;
    position: relative;
    @include mobile {
      width: 100%;
      height: 216.53vw;
    }
    &__textPic {
      position: absolute;
      top: 14vw;
      left: 15.8vw;
      width: 36vw;
      height: 29.3vw;
      background: url("../../assets/運勢書共用/運勢書banner文字.png");
      background-size: contain !important;
      @include mobile {
        width: 73vw;
        height: 63vw;
        background: url("../../assets/運勢書共用/運勢書banner文字_mobile.png");
        top: unset;
        bottom: calc(60px + 68px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__toBuy {
      position: fixed;
      top: 14vw;
      right: 60px;
      z-index: 1;
      @include mobile {
        position: fixed;
        width: 100vw;
        top: unset;
        bottom: 0;
        left: 0;
        display: flex;
        z-index: 54;
      }
      &__btn {
        width: 108px;
        height: 117px;
        cursor: pointer;
        margin: 12px 0;
        @include mobile {
          width: 50%;
          height: 68px;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &__text {
          height: 81px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 5.28px;
          @include mobile {
            height: auto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 1.8px;
          }
          &.hover--show {
            display: none;
            font-size: 17px !important;
            letter-spacing: 1px !important;
            line-height: 100% !important;
            span {
              font-size: 20px !important;
              letter-spacing: 3.6px !important;
            }
          }
        }
        &__price {
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          @include mobile {
            height: auto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          img {
            width: 14px;
            height: 16px;
            margin-left: 6px;
            @include mobile {
              width: 12px;
              height: 13.714px;
              margin-left: 4px;
            }
            &.hover--show {
              display: none;
            }
          }
        }
        //hover(預購)
        &.isSale:hover {
          @include mobile-up {
            border: 1px solid #fff !important;
            background: #d3409d !important;
            .book__banner__toBuy__btn__text {
              color: #fff !important;
              border-bottom: 1px solid #fff !important;
            }
            .book__banner__toBuy__btn__price {
              color: #fff !important;
            }
            .hover--show {
              display: flex !important;
            }
            .hover--hide {
              display: none !important;
            }
          }
        }

        //全部購買
        &.all {
          border: 1px solid #fff;
          background: #214c73;
          @include mobile {
            border: none;
          }
          .book__banner__toBuy__btn__text {
            color: #fff;
            border-bottom: 1px solid #fff;
            @include mobile {
              border-bottom: none;
            }
          }
          .book__banner__toBuy__btn__price {
            color: #fff;
          }
        }
        //單個星座
        &.single {
          border: 1px solid #214c73;
          background: #fff;
          @include mobile {
            border: none;
          }
          @include mobile {
            border: none;
          }
          .book__banner__toBuy__btn__text {
            color: #214c73;
            border-bottom: 1px solid #214c73;
            @include mobile {
              border-bottom: none;
            }
          }
          .book__banner__toBuy__btn__price {
            color: #214c73;
          }
        }
      }
    }

    //手機板預購
    &__mobilePreorder {
      position: fixed;
      z-index: 54;
      width: 100vw;
      top: unset;
      bottom: 68px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.78);
      height: 38px;

      color: #d3409d;
      font-family: Noto Sans TC;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 1.4px;
      .wave-char {
        display: inline-block;
        // animation: wave 1s ease-in-out infinite;
      }
    }
  }

  // Book Main
  &__main {
    margin-top: 18.75vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vw;
    max-width: 840px;
    min-height: 100vh;
    @include mobile {
      margin-top: 36.2vw;
      width: 100%;
      height: 100%;
    }
    &__quote {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        width: 100%;
        height: 100%;
      }
      &__title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38.119px;
        letter-spacing: 2.56px;
        color: #fff;
        margin-bottom: 80px;
        text-align: center;
        @include mobile {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 1.6px;
          margin-bottom: 32px;
        }
        img {
          width: 128px;
          @include mobile {
            width: 80px;
          }
        }
      }
      &__content {
        margin: 0 52px;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 1.92px;
        span {
          display: inline;
          // background-color: #d3409d;
          // padding: 0 8px;
          // border-radius: 8px;
        }
        @include mobile {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 1.12px;
        }
      }
    }
    &__constellations {
      margin-top: 16.4vw;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      &__title {
        margin-bottom: 72px;
        @include mobile {
          width: 95px;
          margin-bottom: 36px;
        }
        img {
          width: 100%;
        }
      }
      &__list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include mobile {
          width: 100%;
          height: 100%;
          justify-content: space-around;
        }
        &__item {
          width: 25%;
          margin-bottom: 84px;
          display: flex;
          flex-direction: column;
          justify-content: centers;
          align-items: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 47.924px;
          letter-spacing: 1.6px;
          color: #fff;
          cursor: pointer;
          @include mobile {
            width: 33%;
            margin-bottom: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 1.12px;
          }
          &__pic {
            width: 220px;
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            background-size: contain !important;
            @include mobile {
              width: 132px;
              height: 132px;
            }
            img {
              width: 108px;
              height: 104px;
              transform: translateY(30px);
              @include mobile {
                width: 64px;
                height: 61px;
                transform: translateY(20px);
              }
            }
            &.background-1 {
              background: url("../../assets/運勢書共用/外框1.png") center center
                no-repeat;
            }
            &.background-2 {
              background: url("../../assets/運勢書共用/外框2.png") center center
                no-repeat;
            }
            &.background-3 {
              background: url("../../assets/運勢書共用/外框3.png") center center
                no-repeat;
            }
          }
          &__title {
            margin-top: -48px;
            font-size: 20px;
            font-weight: 400;
            line-height: 47.924px;
            letter-spacing: 1.6px;
            @include mobile {
              margin-top: -28px;
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: 1.12px;
            }
            img {
              width: 24px;
              margin-right: 8px;
              @include mobile {
                width: 16px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

//蓋版動畫
.book__animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #011e33;
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    video {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }
}

.fade-out {
  transition: opacity 1s;
  opacity: 0;
  pointer-events: none;
}
</style>
