var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "buyNotice" }, [
    _vm._m(0),
    _c("div", {
      staticClass: "buyNotice__content",
      domProps: { innerHTML: _vm._s(_vm.notice) }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyNotice__title" }, [
      _c("img", {
        attrs: { src: require("@/assets/運勢書共用/注意事項icon.svg"), alt: "" }
      }),
      _vm._v("\n    注意事項\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }